html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Sweetalert overrides */
.swal2-html-container {
  font-size: 16px !important;
}
button.swal2-styled {
  min-width: 130px;
  padding: 0;
  font-size: 16px;
}

.container {
  max-width: inherit;
  padding: 0 30px;
}
.text-center {
  text-align: center;
}
